import React, {useState, useEffect} from 'react'
import {EditablePage} from '@magnolia/react-editor'
import {config, getMagnoliaEditablePage} from '../../utils/magnoliaUtils'
import PageNotFound from '@salesforce/retail-react-app/app/pages/page-not-found'
import {useQuery} from '@tanstack/react-query'

const renderMagnoliaEditablePage = ({annotations, page, templateAnnotationsPath}) => {
    // Style used to make sure selected variant in p13n is visible
    const css = '.variantSelector{color:#000}'
    const shouldRender = page && (templateAnnotationsPath ? annotations : true)

    return shouldRender ? (
        page.error?.code === 'pathNotFound' ? (
            <PageNotFound />
        ) : (
            <>
                <style>{css}</style>
                <EditablePage content={page} config={config} templateAnnotations={annotations} />
            </>
        )
    ) : null
}

const getData = async (args) => {
    const response = await getMagnoliaEditablePage(args)
    return response
}

const MagnoliaEditablePage = (args) => {
    const [annotations, setAnnotations] = useState(null)
    const {data, isLoading, error} = useQuery(['magnolia-editable-page', args.match.url], () =>
        getData(args)
    )

    let templateAnnotationsPath = ''
    if (!isLoading) templateAnnotationsPath = data.templateAnnotationsPath
    useEffect(() => {
        if (!isLoading && templateAnnotationsPath) {
            const getAnnotations = async () => {
                const response = await fetch(templateAnnotationsPath)
                const dataRes = await response.json()
                setAnnotations(dataRes)
            }
            getAnnotations()
        }
    }, [templateAnnotationsPath])

    if (error) return <div>An error has occurred: {error.message}</div>

    if (!isLoading && data) {
        const page = data.page
        return renderMagnoliaEditablePage({annotations, page})
    }

    return null
}

MagnoliaEditablePage.getTemplateName = () => 'magnolia-editable-page'

export default MagnoliaEditablePage
